<template>
	<div :class="{ contain: true, mobile: isMobile }" style="overflow-x: hidden">
		<div class="top">
			<div class="ban">
				<img v-if="src" :src="src" alt="" />
			</div>
			<div class="topbox d-flex flex-lg-row flex-row" style="background-color: rgb(39, 6, 69, 0.7)">
				<div class="img ml-7">
					<img v-if="src" :src="src" alt="" />
				</div>
				<div class="right d-flex flex-column">
					<div class="box" v-if="src">
						<div class="toMarket" v-if="this.collectSource != 2 &&this.collectSource != 1&&this.collectSource != 5 && this.ownerName" @click="toMarket">To the market collection  ></div>
						<div class="ava d-flex flex-row">
							<Avatar :did="collectItem.owner" :size="51" :hash="collectItem.creatorPhoto"
								imgType="middlev" showAvatarInfo />
							<div class="d-flex flex-column ml-7 ml-lg-4 createdby">
								<div class="nameBy">Created by</div>
								<div class="name">
									{{collectItem.creatorName ? collectItem.creatorName: collectItem.owner.substr(0, 12) + "..." +
                        collectItem.owner.substr(-12)
                  }}
								</div>
							</div>
						</div>
						<div class="desc mt-2">
							{{ collectItem.name }}
						</div>
						<div class="d-flex flex-row flex-between-center nftcount">
							<div class="sub">NFTs</div>
							<div class="num ml-2">{{ amount }}</div>
						</div>
					 <img
						class="contract"
						width="20px"
						height="20px"
						src="@/assets/icons/icon_contract_v.png"
						alt=""
						@click="contractApprove"
						@mouseenter="mousedcontract()"
						@mouseleave="leavecontract()"
						v-if="
							collectItem.owner == this.$store.state.did &&
							this.collectSource == 5 && !collectItem.haveApply && (collectItem.contractStatus != 2 &&
							collectItem.contractStatus != 4) && collectItem.contractStatus !=1
						"
            		/>
              <img
              class="contract"
              width="20px"
              height="20px"
              src="@/assets/icons/icon_contract_v1.png"
              alt=""
              @click="contractHaveApply"
              @mouseenter="mousedcontract()"
              @mouseleave="leavecontract()"
              v-else-if="collectItem.owner == this.$store.state.did &&
                this.collectSource == 5 && collectItem.haveApply && (collectItem.contractStatus != 2 &&
                  collectItem.contractStatus != 4) && collectItem.contractStatus !=1"
            />
			 <div
              class="contractexplain d-flex flex-row align-center"
              v-if="isshowcontract"
            >
              <span>{{ $t("apply_certification") }}</span>
            </div>
						<img class="message" width="20px" height="20px" src="@/assets/icons/btn_message_w.png" alt=""
							@click="msgBtn" v-if="collectItem.owner != this.$store.state.did" />
						<img class="message" width="20px" height="20px" src="@/assets/icons/icon_edit_w.png" alt=""
							@click="editCollection" @mouseenter="moused()"   @mouseleave="leave()" v-else-if="collectItem.owner == this.$store.state.did && this.collectSource == 5" />
						 <div class="explain d-flex flex-row align-center" v-if="isshow">
               				 <span>Edit Collection</span>
             			 </div>
						 <img class="complaint"  @mouseenter="mousedComplaint()"   @mouseleave="leaveComplaint()" v-if="collectItem.owner != this.$store.state.did && collectItem.contractStatus != 2 && collectSource != 5 " src="@/assets/icons/icon_complaint_w.png" width="20px" height="20px" alt="" @click="nftPlaintBtn"/>
						  <img class="complaint" v-if="(collectItem.contractStatus == 2 || collectItem.contractStatus == 4)&& collectItem.owner == this.$store.state.did" src="@/assets/icons/ban.png" width="20px" height="20px" alt="" @click="AppealBtn" @mouseenter="mousedAppeal()"   @mouseleave="leaveAppeal()"  />
						   <img class="complaint" v-if="(collectItem.contractStatus == 2 || collectItem.contractStatus == 4) && collectItem.owner != this.$store.state.did" src="@/assets/icons/ban.png" width="20px" height="20px" alt=""   @mouseenter="mousedAppeal()"   @mouseleave="leaveAppeal()" />
						  <div class="explains d-flex flex-row align-center" v-if="isshowComplaint">
               				 <span>Report</span>
             			 </div>
						  <div class="appealexplain d-flex flex-row align-center" v-if="isshowAppeal">
               				 <span>Suspected of violations and has been restricted.</span>
             			 </div>
						<div class="rb_icon d-flex justify-end">
							<img class="" src="@/assets/icons/icon_share_w.png" width="22px" height="22px" alt=""
								style="cursor: pointer" @click.stop="shareBtn" />
							<div class="share" v-if="openShare">
								<div class="d-flex flex-row mt-lg-5 mt-5 pointer" style="cursor: pointer"
									:class="{ click: isCopy }" @click="share('copyLink', '')">
									<img class="mx-5" src="@/assets/icons/icon_IPFS.png" alt="Copy Link" />
									<span class="ml-0">
										{{ $t("carddetailShareCopyLink") }}
									</span>
								</div>
								<!-- <div class="d-flex flex-row mt-lg-6 mt-6 pointer " @click="toTweet"> -->
								<div class="d-flex flex-row mt-lg-6 mt-6 pointer" @click="share('twitter', sharePath)"
									style="cursor: pointer">
									<img class="mx-5" src="@/assets/icons/icon_twitter1.png" alt="Tweet" />
									<span class="ml-0">Twitter</span>
								</div>
								<div class="d-flex flex-row mt-lg-6 mb-lg-4 mt-6 mb-4 pointer"
									@click="share('facebook', sharePath)" style="cursor: pointer">
									<v-icon class="mx-lg-5 ml-5 icon mx-5" color="primary" style="margin-top: -4px">
										mdi-facebook
									</v-icon>
									<span class="ml-0 pr-7">Facebook</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tit">
			<p v-html="collectItem.description"></p>
		</div>
		<div class="cards-container">
			<div class="search_create">
				<div class="clearmode box d-flex flex-row">
					<div class="checkbox" v-if="typeStatus == 'createorPage' && !isMobile">
						<v-checkbox v-model="checkbox" @click="checkBoxClick(checkbox)"></v-checkbox>
					</div>
					<div class="content" v-if="typeStatus == 'createorPage' && !isMobile">
						{{ $t("Clean Mode") }}
					</div>
					<CollectionInput style="width: 400px; margin-bottom: 20px" v-if="!isMobile"
						@change="searchChange" />
				</div>
				<div class="create d-flex flex-row">
				
						<div v-if="collectItem.owner != this.$store.state.did">
							<div class="owner"  v-if="this.collectSource != 2 &&this.collectSource != 1 && this.ownerName">
								Owned by
								<span class="ml-1" style="cursor: pointer"
									@mouseenter="mousedOwner()">{{ ownerName }}</span>
							</div>
							<div style="
                  position: absolute;
                  margin-left: -300px;
                  margin-top: 30px;
                  z-index: 1;
                ">
								<CreatorAvator :did="ownerBy" v-if="isShowAva" />
							</div>
						</div>
					


					<v-btn v-if="
              typeStatus == 'createorPage' &&
              collectItem.owner == this.$store.state.did
			  && (collectItem.contractStatus!=2 && collectItem.contractStatus!=4)
            " class="uc createnft mb-4" width="150px" height="41px" rounded @click="createNft">+ Create NFT</v-btn>
				</div>
			</div>

			<div class="cardlist">
			<div v-if="list.length == 0 && !isShowLoading" class="empty d-flex flex-column">
         		 <div class="notFound">No NFTs found</div>
         		 <div class="tryAnother">We couldn't find any NFTs matching your query. Try another query</div>
        	</div>
				<div v-if="typeStatus == 'createorPage'" class="d-flex flex-wrap card">
					<Card v-for="(item, i) in list" :key="i" v-scroll="onScroll" :type="cardType" :value="item"
						owerfrom="my" />
				</div>
				<div v-else class="d-flex flex-wrap card">
					<Card v-for="(item, i) in list" :key="i" v-scroll="onScroll" :type="cardType" :value="item"
						:owerfrom="owerfrom" />
				</div>
				<loading  :isShowLoading="isShowLoading" style="padding-left:500px;"></loading>
			</div>
		</div>
		<div class="right_bottom" v-if="!isMobile">
			<img src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
		</div>
		<div v-if="openmsg" style="position: fixed; right: 20px; bottom: 20px; z-index: 1">
			<message @getOpen="OpenMsg" :currfriend="currfriend"></message>
		</div>
		<v-dialog v-model="opencard">
			<carousel class="otherpage" :list="list" v-if="opencard" @getOpen="OpenCard"></carousel>
		</v-dialog>
		   <!-- 投诉 -->
       <v-dialog v-model="nftComplaint">
      <nft-complaints
        :Nft_sku="collectItem"
		complaintType="collectComplaint"
        @getOpen="openNftcComp"
      ></nft-complaints>
    </v-dialog>
	          <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt"  @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
	<!-- 申诉 -->
		<v-dialog v-model="Appeal">
			<nftAppeal  :parentValue="collectItem" appealType='collectAppeal' @getOpen="Open">
			</nftAppeal>
		</v-dialog>
		<v-dialog v-model="contractOpen" v-if="contractOpen">
      <contractApply
        :Nft_sku="collectItem"
        @getOpen="openContract"
      ></contractApply>
    </v-dialog>
		<uComponents ref="ucom"></uComponents>
	</div>
</template>

<script>
	import Avatar from "@/components/avatar/index.vue";
	import Carousel from "../../components/carousel";
	import message from "@/components/popup/message.vue";
	import CollectionInput from "@/components/CollectionInput.vue";
	import Card from "@/components/workCard/Card.vue";
	// import SortSelect from "@/components/SortSelect.vue";
	import PutOnSale from "@/components/popup/putOnSale.vue";
	import nftComplaints from "@/components/popup/nftComplaints.vue";
	 import Banprompt from "@/components/popup/banprompt.vue";
	 import NftAppeal from "@/components/popup/nftAppeal.vue";
	 import Loading from "@/components/loading.vue";
	 import ContractApply from "@/components/popup/contractApply.vue";
	  import { getSettingArr } from "@/utils/helper";
	import {
		mapState
	} from "vuex";
	import api from "@/api";
	import {
		getFileSrc
	} from "@/utils/file";
	import {
		share
	} from "@/utils";
	import CreatorAvator from "@/components/creatorAvatar/index";
	const COLLECTION_KEY = "COLLECTION";
	let last_time = 0;

	export default {
		name: "CollectionPage",
		components: {
			Carousel,
			message,
			Avatar,
			ContractApply,
			CollectionInput,
			Card,
			// SortSelect,
			PutOnSale,
			CreatorAvator,
			nftComplaints,
			Banprompt,
			NftAppeal,
			Loading
		},
		data: () => ({
			contractOpen: false,
			isshowcontract:false,
			Appeal: false,
			Banprompt:false,
			opencard: false,
			open: false,
			openmsg: false,
			offset: true,
			pageInfo: {},
			did: "",
			currfriend: {},
			list: [],
			sku: {},
			typeStatus: "",
			showRightBottom: false,
			isShowLoading: false,
			isCopy: false,
			imgUrl: "",
			src: "",
			saleStatus: "",
			pageNumber: 1,
			pageSize: 20,
			ownerName: "",
			isshow:false,
			isshowComplaint:false,
			isshowAppeal:false,
			    nftComplaint: false,
			selectList: [{
					value: 1,
					label: "全部 NFT   "
				},
				{
					value: 2,
					label: "未上架销售"
				},
				{
					value: 3,
					label: "已上架销售"
				},
				{
					value: 4,
					label: "已结束的"
				},
			],
			activeSort: 1,
			checkAllCard: false,
			isClick: true,
			isChange: false,
			isShowCheckbox: "",
			showAllCheck: false,
			openShare: false,
			sharePath: "",
			totalNumber: 0,
			openputonsale: false,
			address: "",
			constructID: "",
			name: "",
			onSaleTokenIds: [],
			offSaleTokenIds: [],
			NFTlist: {},
			supplyLimit: 2,
			collectItem: {},
			userData: {},
			url: "",
			empty: false,
			amount: 0,
			checkbox: false,
			cardType: "",
			collectSource: "",
			owerfrom: "",
			isShowAva: false,
			ownerBy: "",
			   NFTInfo:{
        collectionImgUrl:'',
        name:'',
        linkUrl:'',
        description:'',
		collectionMetadata:'',
		id:'',
		totalPage:'',
		loading: false,
		searchInput:''
    },
		}),
		props: {
			collectfrom: {
				type: String,
			},
		},
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			},
			handler: function() {
				return {
					ipt: this.searchInput,
				};
			},
			...mapState({
				searchInput: (state) => state.searchInput,
			}),
		},
		watch: {
			checkbox(ischeck) {
				console.log("wxl --- 88888", ischeck);
				if (ischeck) {
					this.cardType = "clearmode";
					localStorage.setItem("isCollectClear", true);
				} else {
					localStorage.setItem("isCollectClear", false);
					if (this.typeStatus == "createorPage") {
						this.cardType = "my";
					} else {
						this.cardType = "other";
					}
				}
			},

			isMobile() {
				if (this.$vuetify.breakpoint.mobile) {
					this.checkbox = true;
				}
			},
		},
		async mounted() {
			this.showNoRead();
			window.addEventListener("click", this.clickOther);
			window.eventBus.$on('contractApply', this.contractApply);
			window.eventBus.$on('nftAppealed', this.nftAppealed);
			this.init();
			
		},
		methods: {
			contractApply(){
				console.log("wxl  --- contractApply")
				this.init();
			},
			share,
			searchChange(val) {
				 this.$gtag.event('集合主页-搜索', { 'event_category': 'Click', 'event_label': 'search' })
				this.searchInput = val
				clearTimeout(last_time);
				last_time = setTimeout(() => {
					this.list = [];
					this.pageNumber = 1;
					this.getColleectList();
				}, 600);
			},
			nftAppealed(){
				console.log("wxl ---- nftAppealed")
				this.list = [];
				this.pageNumber = 1;
				this.getColleectList();
			},
			async init(){
				this.list = [];
				let collection = this.$route.path;
			this.url = this.$route.params.id;

			if (this.$route.params.source == "firspublish") {
				this.collectSource = 1;
				this.owerfrom = "firspublish";
			} else if (this.$route.params.source == "detaillink") {
				this.collectSource = 2;
				this.owerfrom = "detaillink";
			} else if (this.$route.params.source == "onsale") {
				this.collectSource = 3;
				this.owerfrom = "onsale";
			} else if (this.$route.params.source == "offsale") {
				this.collectSource = 4;
				this.owerfrom = "offsale";
			} else if (this.$route.params.source == "createCollect") {
				this.collectSource = 5;
				this.owerfrom = "createCollect";
			} else {
				this.collectSource = 0;
			}
			let linkUrl = this.$route.params.id;
			let res_info = await api.home.collectionInfo({
				linkUrl
			});
			this.collectItem = res_info.data;
			window.eventBus.$on("collectionPay", this.collection);
			if (collection.indexOf("collection_card") != -1) {
				this.typeStatus = "cardPage";
			} else {
				if (collection.indexOf("collection_create") != -1) {
					 if(this.$route.params.owner != this.$store.state.did)
					 {
						let targetUrl= window.location.href.replace("collection_create","collection").replace("createCollect","detaillink");
						
						 location.href=targetUrl;
					 }
					this.typeStatus = "createorPage";
				} else {
					this.typeStatus = "collectionPage";
				}
			}


			if (this.collectItem.description)
				this.collectItem.description = this.collectItem.description.replace(
					/\n/g,
					"<br/>"
				);

			this.src = await getFileSrc(COLLECTION_KEY, this.collectItem.imgUrl);

			let val_user = window.sessionStorage.getItem("UserData");
			this.userData = JSON.parse(val_user);
			await this.getColleectList();

			let isCheckClearMode = localStorage.getItem("isCollectClear");
			console.log(
				"wxl -- eeeee",
				this.typeStatus,
				this.collectfrom,
				this.checkbox,
				isCheckClearMode
			);

			if (isCheckClearMode == "true" && this.typeStatus == "createorPage" ) {
				this.checkbox = true;
				this.cardType = "clearmode";
			} else {
				this.checkbox = false;
				if (this.typeStatus == "createorPage") {
					this.cardType = "my";
				} else {
					this.cardType = "other";
				}
			}


			this.$forceUpdate();

			},
			createNft() {

				  let json = localStorage.getItem("key_user_grade");
      let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
              	this.$router.push({
					name: "CreateNft",
					query: {
						tokenAddress: this.collectItem.address
					},
				});
            }
			
			},
			  contractApprove() {
      this.contractOpen = true;
    },
    contractHaveApply(){
       this.$toast("success",this.$t('contract_Have_Apply'))
    },
			 mousedcontract() {
      this.isshowcontract = true;
    },
    leavecontract() {
      this.isshowcontract = false;
    },
			mousedOwner() {
				this.isShowAva = true;
			},
			clickOther() {
				this.isShowAva = false;
				this.isShowOwner = false;
				this.openShare = false;
			},
			moused() {
				console.log("wxl --- 3333")
     			 this.isshow = true;
   			 },
   			 leave() {
     			 this.isshow = false;
				},
			mousedComplaint(){
				this.isshowComplaint = true
			},
			leaveComplaint(){
				this.isshowComplaint = false
			},
			mousedAppeal(){
				this.isshowAppeal = true
			},
			leaveAppeal(){
				this.isshowAppeal = false
			},
			 openContract(e) {
      this.contractOpen = e;
    },
			toMarket(){
				// this.$router.push({
				// 	name:"Launch"
				// })
				 let url
      if(this.collectItem.linkUrl)
      { 
       url = this.collectItem.linkUrl
      }else{
       url = this.collectItem.address 
      }
				let collectsource = "detaillink"
				let owner = this.collectItem.owner
			
				 this.$router.push({
          
             path: `/collection/${url}/${collectsource}/${owner}`,   
        });
			},
			async getColleectList() {
				 if (this.loading) {
        		return;
			  }
				  this.loading = true;
				  this.isShowLoading = true
				let params = {
					pageNumber: this.pageNumber,
					pageSize: this.pageSize,
					// tokenType:
					search: encodeURIComponent(this.searchInput || ""),
					// sort
					tokenAddress: this.collectItem.address,
					linkUrl: this.url,
					sourceType: this.collectSource,
					owner: this.$route.params.owner,
				};
				if (this.$route.params.source == "createCollect") {
					params.owner = this.$store.state.did;
				}
				let res = await api.home.getMarketnftList(params);
				let assetList = res.data.list;
				let { totalPage} = res.data;
       			 this.totalPage = totalPage;
				if (assetList != null && assetList.length > 0)
					this.list = this.list.concat(assetList);
				if (res.data.list != null && assetList.length > 0) {
					this.amount = res.data.totalRow;
					this.ownerName = res.data.list[0].ownerName;
					this.ownerBy = res.data.list[0].owner
					if (!this.ownerName) {
						this.ownerName =
							res.data.list[0].owner.substr(0, 6) +
							"..." +
							res.data.list[0].owner.substr(-6);
					}
				}

				if (
					(this.pageNumber == 1 || this.pageNumber == 0) &&
					this.list.length == 0
				) {
					this.empty = true;
				} else {
					this.empty = false;
				}
				this.loading = false;
				this.isShowLoading = false
			},
			collection() {
				this.list = [];
				this.pageNumber = 1;
				this.getColleectList();
			},
			onScroll(e) {
				const {
					scrollTop,
					clientHeight,
					scrollHeight,
				} = e.target.scrollingElement;
				if (
					scrollTop + clientHeight >= scrollHeight &&
					this.totalPage > this.pageNumber
				) {
					  if (!this.loading) {
        			this.pageNumber++;
      				}
					// this.pageNumber++;
					this.getColleectList();
				}
			},
			shareBtn() {
				 this.$gtag.event('集合主页-分享', { 'event_category': 'Click', 'event_label': '分享' })
				this.openShare = true;
			},
			msgBtn() {
				this.$gtag.event('集合主页-留言', { 'event_category': 'Click', 'event_label': '留言' })
				this.currfriend = {
					fansName: this.collectItem.creatorName,
					fansAddress: this.collectItem.owner,
				};
				this.openmsg = true;
			},
			  nftPlaintBtn() {
		this.$gtag.event('集合主页-投诉', { 'event_category': 'Click', 'event_label': '投诉' })
      this.nftComplaint = true;
    },
    openNftcComp(e) {
      this.nftComplaint = e;
	},
	//申诉弹层
			AppealBtn() {
				if(	this.collectItem.contractStatus == 4){
					this.$toast("error", 'Appealed');
				}else{
					this.Appeal = true;
				}	
				
			},
			Open(e) {
				this.Appeal = e;
			},
	 openBanprompt(e){
         this.Banprompt = e
      },
			editCollection(){
       				this.NFTInfo.collectionImgUrl = this.collectItem.imgUrl
       				 this.NFTInfo.name = this.collectItem.name
       				 this.NFTInfo.linkUrl = this.collectItem.linkUrl?this.collectItem.linkUrl:this.collectItem.address
       				 this.NFTInfo.description = this.collectItem.description
					this.NFTInfo.collectionMetadata = this.collectItem.collectionMetadata
					this.NFTInfo.id = this.collectItem.id
        			this.$router.push({ name: "Create",params:{
    	 			 NFTInfo:this.NFTInfo
   					 } });

    		},
			//弹框关闭后获取open值
			OpenMsg(e) {
				this.openmsg = e;
			},
			openCarousel() {
				this.opencard = true;
			},
			OpenCard(e) {
				this.opencard = e;
			},
			toCopyLink() {
				// http://web.upticknft.com:8084/#/saledetail?id=7666
				var input = document.createElement("input");
				input.value =
					process.env.VUE_APP_BASE_URL +
					`#/saledetail?id=${this.$route.query.id}`;

				document.body.appendChild(input);
				input.select();
				document.execCommand("copy");
				document.body.removeChild(input);
				this.isCopy = true;
				setTimeout(() => (this.isCopy = false), 100);

				//xxl TODO need to add tip
			},

			toTweet() {
				// alert("abc ...");
				var meta = document.createElement("meta");
				//meta.setAttribute('property', 'og:image');
				meta.setAttribute("name", "twitter:image:src");
				meta.setAttribute(
					"content",
					"https://cdn.cms-twdigitalassets.com/content/dam/developer-twitter/redesign-2021-images/og-social-card/devwebsite_card_tn.jpg.twimg.768.jpg"
				);
				document.getElementsByTagName("head")[0].appendChild(meta);

				//window.open("http://twitter.com/share?text=Im Sharing on Twitter&url=http://stackoverflow.com/users/2943186/youssef-subehi&hashtags=stackoverflow,example,youssefusf")
				window.open(
					"https://twitter.com/intent/tweet?text=Hello%20world&url=http://graphics8.nytimes.com/images/2012/02/19/us/19whitney-span/19whitney-span-articleLarge.jpg"
				);
				//window.open("https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fdeveloper.twitter.com%2F&amp;ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&amp;text=Guides%20%7C%20Docs%20%7C%20Twitter%20Developer%20Platform&amp;url=https%3A%2F%2Fdeveloper.twitter.com%2Fen%2Fdocs%2Ftwitter-for-websites%2Ftweet-button%2Foverview")
			},
			 async showNoRead(){
    const json = localStorage.getItem("key_user");
    if (json) {
      let res2 = await api.home.isNewMessage({userAddress:this.$store.state.did,pushTypes:getSettingArr()});
      let newMsgCount = res2.data;


     // 有新消息进行弹窗
     if(newMsgCount >0){
        let params={
        pageSize: 10,
        pageNumber: 1,
        owner:this.$store.state.did,
		isRead:false,
		pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      console.log("wxl --- sysMessage",res)
      if(res.data){
         let list = res.data.list
         for (let index = 0; index < list.length; index++) {
           setTimeout(() => {
              this.$mtip({
                title:list[index].pushContent,
              });
           }, 1000);
           
         }
      }      
    }
   }
   window.eventBus.$emit("ViewMessage", true);
   },
		},
	};
</script>

<style lang="scss" scoped>
	.contain {
		padding: 0;
		margin: 0;

		.top {
			max-width: 1220px;
			height: 401px;
			margin: 0 auto;
			position: relative;

			.ban {
				max-width: 1220px;
				height: 401px;
				margin: 0 auto;
				opacity: 0.8;

				img {
					width: 100%;
					height: 100%;
					display: block;
					object-fit: cover;
				}
			}

			.topbox {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				margin: 0 auto;

				.img {
					width: 535px;
					height: 355px;
					margin-right: 50px;

					img {
						object-fit: contain;
						display: block;
						margin-top: 20px;
						width: 100%;
						height: 100%;
					}
				}

				.right {
					padding: 35px 0 25px;

					.rt_icon {
						.oneicon {
							width: 20px;
							height: 20px;
						}
					}

					.rb_icon {
						position: absolute;
						bottom: 30px;
						right: 30px;

						.twoicon {
							width: 22px;
							height: 26px;
						}
					}

					.message {
						position: absolute;
						cursor: pointer;
						bottom: 30px;
						right: 76px;
					}
					.complaint{
						position: absolute;
						cursor: pointer;
						bottom: 30px;
						right: 120px;

					}
					 .contract {
							// pointer-events: none;
							position: absolute;
							cursor: pointer;
							bottom: 30px;
						right: 120px;
        }

					.box {
						min-width: 543px;
						margin: auto;
						.toMarket{
							cursor: pointer;
							position: absolute;
							top:-23px;
							right:25px;
							font-family: Helvetica;

							font-size: 15px;
							font-weight: bold;
							font-stretch: normal;
							line-height: 100px;
							letter-spacing: 0px;
							color: #ffffff;
						}
						.ava {
							align-items: center;

							.nameBy {
								min-height: 17px;
								font-family: Helvetica;
								font-size: 14px;
								font-stretch: normal;
								letter-spacing: 0px;
								color: #ffffff;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

							.name {
								min-height: 17px;
								font-family: Helvetica;
								font-size: 15px;
								font-weight: bold;
								font-stretch: normal;
								letter-spacing: 0px;
								color: #ffffff;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}
						}

						.desc {
							margin-bottom: 30px;
							margin-right: 30px;
							width: 600px;
							font-family: Helvetica;
							font-size: 40px;
							font-weight: bold;
							font-stretch: normal;
							letter-spacing: 0px;
							color: #ffffff;
							word-break: break-all;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							overflow: hidden;
							-webkit-box-orient: vertical;
						}

						.nftcount {
							.num {
								font-family: Helvetica;
								font-weight: bold;
								font-size: 15px;
								color: #ffffff;
							}

							.sub {
								font-family: Helvetica;
								font-weight: bold;
								font-size: 15px;
								color: #ffffff;
							}
						}
						 .explain {
							width: 110px;
							height: 30px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 375px;
							right: 10px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
    					}
						   .contractexplain {
								width: 150px;
								height: 30px;
								background-color: #270645;
								border-radius: 3px;
								position: absolute;
								top: 373px;
								right: 133px;
								span {
								font-family: Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
            }
          }
						 .explains {
							width: 110px;
							height: 30px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 375px;
							right: 27px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
    					}
						 .appealexplain {
							width: 185px;
							height: 56px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 375px;
							right: 120px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
    					}
						
					}
				}
			}
		}

		.tit {
			text-align: center;
			margin-bottom: 29px;

			p {
				min-height: 100px;
				margin: 29px auto 0;
				max-width: 1018px;
				min-height: 0px;
				font-family: Helvetica;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
			}
		}

		.share {
			width: 166px;
			height: 161px;
			background-image: linear-gradient(#ffffff, #ffffff),
				linear-gradient(#6f58d9, #6f58d9);
			background-blend-mode: normal, normal;
			box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
			border-radius: 5px;
			position: absolute;
			right: 20px;
			top: 30px;
			z-index: 20000;

			img,
			.icon {
				display: inline-block;
				width: 25px;
				height: 25px;
			}

			span {
				font-family: "\5FAE\8F6F\96C5\9ED1";
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
			}
		}

		.right_bottom {
			position: fixed;
			bottom: 120px;
			right: 50px;

			img {
				display: block;
				width: 59px;
				height: 59px;
				margin-top: 35px;
			}
		}

		.cards-container {
			.search_create {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.clearmode {
					margin-left: 10px;

					.checkbox {
						display: flex;
						align-items: center;
					}

					.content {
						display: flex;
						align-items: center;
						margin-right: 40px;
						height: 83px;
						font-size: 14px;
						font-weight: bold;
					}
				}

				.create {
					.owner {
						display: flex;
						align-items: center;
						margin-right: 30px;
						font-size: 14px;
						font-weight: bold;

						span {
							color: #1d42ff;
						}
					}
				}
			}

			.cardlist {
				display: flex;
				flex-wrap: wrap;
				 .empty {
      width: 100%;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      .notFound{
        font-family: Helvetica;
        font-size: 24px;
        font-weight: bold;

      }
      .tryAnother{
          font-family: Helvetica;
        font-size: 18px;
        width: 320px;
        text-align: center;
      }
    }

				.v-card {
					margin-right: 30px;
					margin-bottom: 30px;

					&:nth-child(5n) {
						margin-right: 0;
					}
				}

				.v-card.mobile {
					width: 100%;
					margin-right: 0;
				}

				.empty {
					width: 100%;
					height: 500px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		&.mobile {
			width: 100%;

			.card {
				width: 100%;
				margin-right: unset;
				justify-content: space-between;
			}

			.cards-container {
				margin-right: unset;
			}

			.v-card {
				margin-right: unset !important;
				width: 46%;
			}

			.img {
				margin-right: unset !important;
			}

			.tit {
				margin-bottom: 20px;
			}

			.search_create {
				margin-bottom: 20px;
			}

			.desc {
				font-size: 30px !important;
				width: 295px !important;
				word-break: keep-all !important ;
			}

			.createdby {
				margin-left: 15px !important;
			}
		}
	}
</style>
