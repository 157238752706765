<template>
   <div id="container">
      <v-card :class="{ card: true, mobile: isMobile }">
         <img
             class="clo"
             @click="closeDialog"
             src="@/assets/btn_close.png"
             alt=""
         />
         <div class="top ">
              <h3 >{{$t("apply_certification")}}</h3>
              <div class="d-flex flex-lg-row flex-column">
                  <div class="left">   
                <div class="content d-flex flex-lg-row flex-row mt-lg-1">
               <img :src="Imgsrc" alt=""/>
               <div class="d-flex flex-column">
                  <div class="rgh ">
                  <div class="nftName">{{NftName}}</div>
               </div>
               <div class='contarct'>{{$t('Contract')}} {{Nft_sku.address.substr(0, 6) + "..." + Nft_sku.address.substr(-6)}}</div>
               </div>
               
            </div>
             </div>
             <div class="right">   
                <div class="content d-flex flex-lg-row flex-row mt-lg-1">
               <!-- <img :src="Imgsrc" alt=""/> -->
              <v-avatar class="" size="51" >
             <v-img :src="CreatorPhotosrc" alt="Midoriya" />
         </v-avatar>
               <div class="d-flex flex-column ml-4">
                   <div class="rgh ">
                  <div class="nftName">{{ $t("created_by") }} <span>{{Nft_sku.issuerName}}</span> </div>
               </div>
               <div class='contarct'> {{Nft_sku.owner.substr(0, 6) + "..." + Nft_sku.owner.substr(-6)}}</div>
               </div>
               
            </div>
             </div>
              </div>
             
             
           
         </div>
         <!-- <div class="channel d-flex flex-row">
            <div class="channelTitle">{{ $t("Release_channel") }} <span>Uptick on Loopring</span> </div>
         </div> -->
       
         <div class="ti-tx">
            <span class="title">{{$t("popupAccountAppealDescription")}}<span class="star">*</span></span>
            <v-textarea
                class="txt"
                label=""
				 :placeholder="this.$t('certificationDes')"
                v-model="firsttxt"
                outlined
                rows="5"
                ref="firsttxt"
                :rules="[rules.textRules]"
                row-height="18"
                counter="800"
            ></v-textarea>
         </div>
         <div style="margin-left: 52px" class="upload">
            <div style="display: flex;">
               <div class="uploadfile" v-for="(item,index) in uploadfile" :key="index">
                  <img class="img" :src="item"/>
                  <img class="icon" src="@/assets/btn_close.png" @click="imgRemove(index)"/>
               </div>

               <div class="uploadfile" v-if="uploadfile.length<3">
                  <FIleInput class="FIleInput" @change="change" :key="fileInputKey" accept=".jpg,.jpeg,.png,.gif">
                     <v-icon color="black">mdi-plus</v-icon>
                  </FIleInput>
               </div>
            </div>
            <span class="file-limit">{{$t("popupNftAppealLimit")}}<span class="star">*</span></span>
         </div>
         <div class="emailinp">
            <span class="title">{{$t("Sociallinks")}}<span class="star">*</span></span>
            <v-textarea
                label=""
                v-model="socialValue"
                outlined
                rows="3"
                row-height="20"
                :placeholder="$t('socialDes')"
            ></v-textarea>
         </div>
         <div class="box d-flex flex-column">
          <span class="title ">
            {{$t("Contactinformation")}}
            <span class="star">*</span>
          </span>
          <v-text-field
            class="txt"
            v-model="contractValue"
            height:10px
            full-width:false
            outlined
            :placeholder="$t('contractIndoDes')"
          ></v-text-field>
        </div>
         
         <div class="footer">
             <v-btn class="sub" submit  disabled v-if="subDisabled">{{$t("popupTransferCommit")}}</v-btn>
            <v-btn class="sub" submit v-else @click="subCommit">{{$t("popupTransferCommit")}}</v-btn>
         </div>
      </v-card>
      <PromptBox ref="promptBox" @commit="promptBoxCommit"></PromptBox>
       <uComponents  ref="ucom"></uComponents>
   </div>
</template>

<script>
  import api from "@/api";
  import FIleInput from "@/components/FIleInput.vue";
  import { getFileSrc } from "@/utils/file";
  import PromptBox from "@/components/PromptBox.vue";

const UPLOAD_KEY = "UPLOAD";
  const WORK_KEY = "WORK";
  //NFT投诉
  export default {
    components: { FIleInput, PromptBox },
    name: "nftComplaints",
    props: {
      Nft_sku: {
        type: Object
      },
      complaintType:{
         type:String
      }
    },
    data: function() {
		return{
     
      newOpen: true,
      firsttxt: "",
      socialValue: "",
      tineValue: "",
       subDisabled: false,
      Imgsrc: "",
      CreatorPhotosrc:'',
      NftName: "",
      complainantName:'',
      contractValue:'',
      uploadfile: [],
      imgUrl: [],
      fileInputKey: 0,
      rules: {
        textRules: value => value.length <= 800 || "Max 800 characters."
      },
      rule: {
        emailRules: value => !!value || 'Email is required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
	  }
    },
    computed: {
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }

    },
    watch: {
    firsttxt: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },
    socialValue: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },
    contractValue: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },
    uploadfile: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },
  },
    filters: {
      didfilter: function(value) {
        if (value && value.length > 12) {
          return value.substr(0, 10) + "..." + value.substr(-8);
        } else {
          return "";
        }
      }
    },
    async mounted() {
       console.log("wxl --- wew",this.Nft_sku)
       window.eventBus.$on('LangChange', this.onLangChange);

      if (this.Nft_sku.imgUrl != null) {
        this.Imgsrc = await getFileSrc(WORK_KEY, this.Nft_sku.imgUrl);
      }
      if (this.Nft_sku.creatorPhoto != null) {
        this.CreatorPhotosrc = await getFileSrc(WORK_KEY, this.Nft_sku.creatorPhoto);
      }
      

      this.NftName = this.Nft_sku.name;

  
      //this.complainantName = this.Nft_sku.issuerName;
      this.tineValue = this.$store.state.did;

      // this.tineValue = this.Nft_sku.owner;

    },
    methods: {
       submitStatus() {
      this.subDisabled = !(
        this.firsttxt &&
        this.socialValue &&
        this.contractValue &&
        this.uploadfile.length > 0
      );
    },
      verify() {
     
        let textVer = this.$refs.firsttxt.validate(true);

        !textVer ? this.$refs.firsttxt.focus() : "";
        
        return textVer;
      },
      async subCommit() {

        if(!this.verify()) {
          return;
        }
        debugger
        let params = {
           nftAddress:this.Nft_sku.address,
           creator:this.Nft_sku.owner,
          description: encodeURIComponent(this.firsttxt),
          socialLinks: encodeURIComponent(this.socialValue),
          contactInformation:encodeURIComponent(this.contractValue),
          applicationMaterials: this.imgUrl.join(",")
        };

        let res = await api.home.cerfifiedApply(params);
        if (res.success == true) {
               this.$toast("success", this.$t("success")).then(() => {
                 window.eventBus.$emit("contractApply");
            this.closeDialog();
        });
        }else{
            this.$toast('error',res.msg)
        }
       
      },
      promptBoxCommit() {
          this.closeDialog();
      },
      //关闭弹框，发送open值
      closeDialog() {
        this.newOpen = false;
        this.$emit("getOpen", this.newOpen);
      },
     
      async change(file) {
         let ft = file.name.substr(file.name.lastIndexOf(".") + 1);
      if ("JPG,JPEG,PNG,GIF".indexOf(ft.toUpperCase()) == -1) {
        this.$toast("error", this.$t("file_type_not_support"));
        this.fileInputKey++;
        return;
      }
       if (file.size > 10242880) {
          this.$toast("error", this.$t("image_not_support"));
          return;
        }

 let {path} = await this.ipfsAddFile(file);
		  this.fileInputKey++;
		  let url = this.getImageIpfs(path);
        this.uploadfile.push(url);
         this.imgUrl.push(path);

        
    

      },

      imgRemove(idx) {
        this.uploadfile.splice(idx, 1);
      },
       onLangChange() {
        
       }
    }
  };
</script>

<style lang="scss" scoped>
.star {
   color: red;
   font-size: 18px;
   font-family: "宋体";
   margin-left: 10px;
     }
   .card {
      display: flex;
      flex-direction: column;
      min-height: 769px;
      width: 600px;

      .clo {
         position: absolute;
         right: 10px;
         top: 10px;
         width: 30px;
         height: 31px;
         background-color: #fff;
         border-radius: 50%;
         margin-right: 0;
         cursor: pointer;
      }
      .channel{
           margin: 10px 52px;
         .channelTitle{
            font-family: Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
            span{
               display: inline;
               font-family: Helvetica;
               font-size: 13px;
               font-weight: normal;
               font-stretch: normal;
               margin-left: 10px;
               letter-spacing: 0px;
               color: #270645;
            }
         }
      }

      .top {
         display: flex;
         flex-direction: column;

         h3 {
            margin: 41px 0 17px 52px;
            font-weight: bold;;
            font-size: 25px;
            color: #270645;
         }
         .left{
              .content {
            display: flex;
            flex-direction: row;
            img{
               width: 51px;
               height: 51px;
            }
            .rgh {
               display: flex;
               flex-direction: column;

               .ic-sp {
                  display: flex;

                  .icon {
                     display: flex;
                     align-items: flex-end;
                     width: 41px;
                     height: 40px;
                     background-color: #6f58d9;
                     border-radius: 50%;
                     margin-left: 0;
                     margin-top: 10px;
                  }
               }

               .nftName {
                      font-family: Helvetica;
                  font-weight: bold;;
                  font-size: 13px;
                  color: #270645;
                  margin-bottom: 10px;
                            overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 200px;
   
               }
            }
            .contarct{
               	width: 187px;
               font-family: Helvetica;
               font-size: 11px;
               font-weight: normal;
               font-stretch: normal;
               letter-spacing: 0px;
               color: #270645;
            }
         }
         }
          .right{
              .content {
            display: flex;
            flex-direction: row;
            img{
               width: 80px;
               height: 80px;
               margin: 1px 22px 10px 10px;
            }
            .rgh {
               display: flex;
               flex-direction: column;

               .ic-sp {
                  display: flex;

                  .icon {
                     display: flex;
                     align-items: flex-end;
                     width: 41px;
                     height: 40px;
                     background-color: #6f58d9;
                     border-radius: 50%;
                     margin-left: 0;
                     margin-top: 10px;
                  }
               }

               .nftName {
                  font-weight: bold;;
                  font-size: 13px;
                  color: #270645;
                  margin-bottom: 10px;
                         overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 200px;
                  span{
                     display:inline;
                     font-stretch: normal;
                     font-weight: normal;
                     letter-spacing: 0px;
                     color: #270645;
                  
               

                  
                  };
               }
            }
            .contarct{
               	width: 187px;
               font-family: Helvetica;
               font-size: 11px;
               font-weight: normal;
               font-stretch: normal;
               letter-spacing: 0px;
               color: #270645;
            }
         }
         }

        
      }

     

      .ti-tx {
         margin: 10px 52px;

         .txt {
            width: 500px;
            height: 166px;
            background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
            background-blend-mode: normal, normal;
         }
          .title{
               font-family: Helvetica !important;
            font-size: 15px !important;
            font-weight: bold !important;
                 
      
         }
         

      }

      .upload {
         .uploadfile {
            margin-bottom: 14px;
            width: 101px;
            height: 101px;
            background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
            background-blend-mode: normal, normal;
            border-radius: 1px;
            border: dashed 2px #e3e3e3;

            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 30px;
            cursor: pointer;

            .FIleInput {
               width: 100%;
               height: 100%;

               .v-icon {
                  width: 100%;
                  height: 100%;
                  padding: 25px;
               }

            }

            .icon {
               width: 20px;
               height: 20px;
               border-radius: 50%;
               position: absolute;
               top: -10px;
               left: 30px;
               background-color: #fff;
               z-index: 99;
               cursor: pointer;
            }

            .img {
               width: 100%;
               height: 100%;
               margin: 0;
            }
         }

         .file-limit {
            // width: 137px;
            height: 13px;
            font-family: Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #766983;
         }
      }

      .emailinp {
         margin: 10px 52px;
          .title{
               font-family: Helvetica !important;
            font-size: 15px !important;
            font-weight: bold !important;
         }
      }
      .box{
          margin: -30px 52px 10px 52px;
          .title{
               font-family: Helvetica !important;
            font-size: 15px !important;
            font-weight: bold !important;
         }
      }


      .footer {
         display: flex;
         flex-direction: column;
         text-align: center;
         margin-bottom: 50px;

         .sub {
            margin: 0 auto;
            width: 450px;
            height: 51px;
           background-color: #7800f4;
            border-radius: 25px;
            opacity: 0.9;
            color: #fff;
            font-weight: bold;;
            font-size: 20px;
         }
      }

      &.mobile {
         .top{
            .right{
               margin-left: 52px;
               margin-top: 20px;
               .content{

               }
            }
            
         }
          .ti-tx{
             margin: 0 52px;
             .title{
                margin-top: 0;
             }
          }

         .footer {
            .sub {
               width: 80%;
            }
         }
        
      }
   }

   img {
      display: inline-block;
      width: 126px;
      height: 126px;
      border-radius: 5px;
      margin: 1px 15px 10px 52px;
   }

   span {
      display: flex;
      align-items: center;
      font-family: Helvetica;
      color: #270645;
   }

   .title {
      font-weight: bold;;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      color: #270645;
   }
   
</style>


